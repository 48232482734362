$(function () {
  const forms = $('.form-redirect');

  if (forms.length === 0) {
    return;
  }

  forms.each(function () {
    const $button = $(this).find('button#submit');
    const $form = $(this)[0];
    const $redirectUrl = $form.dataset.redirect;

    if ($redirectUrl !== undefined && $redirectUrl !== '') {
      document.addEventListener('wpcf7mailsent', function (event) {
        setTimeout(() => {
          const $target = $(event.target);
          const $targetButton = $target.find('button#submit');

          if ($targetButton[0] === $button[0]) {
            window.location = $redirectUrl;
          }
        }, 1000);
      }, false);
    }
  });
});
