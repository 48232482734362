import tab from 'bootstrap/js/src/tab';

$(function () {
  const $mockup = $('.mockup-inner');
  const $tabsButtons = $('#mockup-tab .mockup-link');
  const $progressBars = $('.mockup-element .mockup-progress .col .progress .progress-bar');
  const TRANSITION_DURATION = 5000;
  const TRANSITION_ANIMATION = 4900;

  if ($mockup.length === 0) return;

  let intervalId = null;
  let $currentTab = $tabsButtons.filter('.active');
  let $currentTransitionProgress = $progressBars.eq($tabsButtons.index($currentTab));

  const emptyProgress = () => {
    if ($currentTransitionProgress) $currentTransitionProgress.finish();
    $progressBars.css('width', '0');
  }

  const animateProgress = ($progress, firstTime = false) => {
    $currentTransitionProgress = $progress;
    $currentTransitionProgress.css('width', '0');

    if (!firstTime) emptyProgress();

    $currentTransitionProgress.animate({width: '100%'}, {
      duration: TRANSITION_ANIMATION,
      easing: 'linear'
    });
  }

  const switchToNextTab = (runOnce) => {
    $currentTab = $tabsButtons.filter('.active');
    let $nextTab = $currentTab.next();

    if (!$nextTab.length) {
      $nextTab = $tabsButtons.first();
      emptyProgress();
    }

    animateProgress($progressBars.eq($tabsButtons.index($nextTab)));

    const bootstrapTabInstance = tab.getOrCreateInstance($nextTab);
    if (bootstrapTabInstance) bootstrapTabInstance.show();

    if (!runOnce) startInterval();
  }

  const startInterval = () => {
    intervalId = setInterval(() => switchToNextTab(true), TRANSITION_DURATION);
  }

  $tabsButtons.each(function () {
    $(this).on('click', function () {
      $currentTab = $(this);
      let $nextTab = $currentTab.next();

      clearInterval(intervalId);
      startInterval();

      if (!$nextTab.length) $nextTab = $tabsButtons.first();

      emptyProgress();

      $currentTransitionProgress = $progressBars.eq($tabsButtons.index($currentTab));
      animateProgress($currentTransitionProgress);
    });
  });

  setTimeout(() => {
    animateProgress($currentTransitionProgress, true);
    startInterval();
  }, 500);
});
