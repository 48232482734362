import * as basicScroll from 'basicscroll'

$(function () {
    let parallax_sections = document.querySelectorAll('.uq-parallax');

    for (let parallax_section of parallax_sections) {
        let _from = parallax_section.dataset.from || '0px';
        let _to = parallax_section.dataset.to || '100px';

        let instance = basicScroll.create({
            elem: parallax_section,
            from: 'bottom-bottom',
            to: 'top-top',
            direct: true,
            props: {
                '--ty': {
                    from: '-100px',
                    to: '0',
                    timing: 'expoOut',
                }
            }
        })

        instance.start();
    }

    let parallax_alt_sections = document.querySelectorAll('.uq-parallax-alt');

    for (let parallax_section of parallax_alt_sections) {
        let _from = parallax_section.dataset.from || '0px';
        let _to = parallax_section.dataset.to || '100px';

        let instance2 = basicScroll.create({
            elem: parallax_section,
            from: 'bottom-bottom',
            to: 'top-top',
            direct: true,
            props: {
                '--ty': {
                    from: '-50px',
                    to: '100px',
                    timing: 'expoOut',
                }
            }
        })

        instance2.start();
    }

    let parallax_blob_sections = document.querySelectorAll('.uq-parallax-blob');

    for (let parallax_blob of parallax_blob_sections) {
        let _from = parallax_blob.dataset.from || '0px';
        let _to = parallax_blob.dataset.to || '100px';

        let instanceBlob = basicScroll.create({
            elem: parallax_blob,
            from: 'top-bottom',
            to: 'bottom-top',
            direct: true,
            props: {
                '--ty': {
                    from: _from,
                    to: _to
                }
            }
        })

        instanceBlob.start();
    }
})
