$(function () {
    const $PRICES = $('#paymentplan');
    const $MONTHPRICES = $('.price.month');
    const $YEARPRICES = $('.price.year');

    $PRICES.on('input', function() {
        const isYear = $(this).hasClass('year');

        $(this).toggleClass('year', !isYear);
        $(this).toggleClass('month', isYear);

        $MONTHPRICES.toggleClass('month', isYear);
        $MONTHPRICES.toggleClass('d-none', !isYear);

        $YEARPRICES.toggleClass('year', !isYear);
        $YEARPRICES.toggleClass('d-none', isYear);
    });
});
