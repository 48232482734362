try {
  window.$ = window.jQuery;

  document.addEventListener('DOMContentLoaded', function () {
    require('./parts/Parallax');
    require('./parts/HeroTab.js');
    require('./parts/Accordion.js');
    require('./parts/Switch.js');
    require('./parts/FormRedirect.js');

    // This will not work due to lazy loading, moved to cookie-script.blade.php
    // require('./cookie.js');
  });
} catch (e) {
  console.error(e);
}
