$(function () {
  const $ACCORDION = $('#accordion-element .accordion-item');
  const $ACCORDION_BUTTONS = $ACCORDION.find('.accordion-button');

  let $activeElement = '';
  let $previousToActiveElements = '';
  let $nextToActiveElements = '';

  if ($ACCORDION.length > 0) {
    searchForActiveElement();
  }

  function searchForActiveElement() {
    $activeElement = $ACCORDION_BUTTONS.filter('[aria-expanded="true"]');
    $previousToActiveElements = $activeElement.parent().parent().prevAll();
    $nextToActiveElements = $activeElement.parent().parent().nextAll();
  }

  function placeColorClasses($elements, $addClass) {
    $elements.each(function () {
      let $el = $(this).find('.accordion-button');

      if ($addClass && !$el.hasClass('text-white')) {
        $el.addClass('text-white');
      } else if (!$addClass) {
        $el.removeClass('text-white');
      }
    });
  }

  $ACCORDION_BUTTONS.each(function () {
    let $button = $(this);

    $button.on('click', function () {
      searchForActiveElement();

      placeColorClasses($previousToActiveElements, true);
      placeColorClasses($nextToActiveElements, false);
    });
  });
});